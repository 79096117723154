<template>
  <base-section id="hynosetherapie">
    <base-section-heading title="Hypnosetherapie" />

    <v-container>
      <!--<v-row>
        <v-col
          v-for="(feature, i) in features"
          :id="feature.title"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
        </v-col>
      </v-row>
      <v-divider />-->
      <v-col
        cols="12"
        md="12"
      >
        <base-title
          class="primary--text"
          title="Was ist Hynosetherapie?"
          tag="div"
        />

        <base-body>
          Die Hypnose ist ein Zustand der Tiefenentspannung. Sie ermöglicht einen direkten Zugang zum Unterbewusstsein. Hier liegen die tiefen Ursachen von Traumatas, Ängsten, Blockaden und Süchten etc. Das Problem wird an der Wurzel gelöst. Hypnose eignet sich beinahe für alle, die eine Veränderung wirklich wollen.
          <br><br>
          - In der Hypnose behält man immer die volle Kontrolle über sich und kann sich anschliessend an alles erinnern.<br>
          - Hypnose ist ein natürlicher Wachzustand (kein Schlaf, kein Koma).<br>
          - Niemand kann hypnotisiert werden, der das nicht möchte.<br>
          - Niemand kann in der Hypnose steckenbleiben.
        </base-body>

        <base-title
          class="primary--text"
          title="Wie arbeite ich?"
          tag="div"
        />

        <base-body>
          Da mir eine direkte und effiziente Methode am meisten zusagt, habe ich mich für die aufdeckende Hypnose nach Dave Elman und Gerhard Kein entschieden. In der aufdeckenden Hypnose geht es darum, behindernde Verhaltensmuster eines Menschen zu ändern. Die Ursache, respektive der Auslöser für das Problem wird gesucht und aufgelöst. Diese Methode ist sehr effektiv und bedingt nur wenige Sitzungen. Oft wird das Problem schon in einer Sitzung gelöst.
          <br> Mir ist dabei wichtig, eine vertrauensvolle Basis zu meinen Klienten aufzubauen. Mit Sorgfalt und Achtsamkeit gehen wir gemeinsam auf die Suche nach der Lösung im Unterbewusstsein.
        </base-body>
        <base-title
          class="primary--text"
          title="In welchen Themen kann Hypnosetherapie helfen?"
          tag="div"
        />
        <v-row>
          <v-col
            v-for="(text, i) in marketing"
            :key="i"
            cols="12"
            md="6"
          >
            <base-list-item :text="text" />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-dots-circle',
          title: 'Was ist Hynosetherapie?',
          text: 'Die Hypnose ist ein Zustand der Tiefenentspannung. Sie ermöglicht einen direkten Zugang zum Unterbewusstsein. Hier liegen die tiefen Ursachen von Traumatas, Ängsten, Blockaden und Süchten etc. Das Problem wird an der Wurzel gelöst. Hypnose eignet sich beinahe für alle, die eine Veränderung wirklich wollen.',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-circle-double',
          title: 'Wie arbeite ich?',
          text: 'Da mir eine direkte und effiziente Methode am meisten zusagt, habe ich mich für die aufdeckende Hypnose nach Dave Elman und Gerhard Kein entschieden. In der aufdeckenden Hypnose geht es darum, behindernde Verhaltensmuster eines Menschen zu ändern. Die Ursache, respektive der Auslöser für das Problem wird gesucht und aufgelöst. Diese Methode ist sehr effektiv und bedingt nur wenige Sitzungen. Oft wird das Problem schon in einer Sitzung gelöst. Mir ist dabei wichtig, eine vertrauensvolle Basis zu meinen Klienten aufzubauen. Mit Sorgfalt und Achtsamkeit gehen wir gemeinsam auf die Suche nach der Lösung im Unterbewusstsein.',
        },
      ],
      marketing: [
        'Ängste und Blockaden',
        'Depressionen',
        'Verarbeitung von schweren Erlebnissen',
        'Loslassen',
        'Phobien',
        'Ganzheitliche Zusammenhänge erkennen',
        'Schlafstörungen',
        'Krankheiten',
        'Selbstwertgefühl',
        'Traumas auflösen',
        'Suchterkrankungen',
        'Selbsthypnose lernen',
        'Unerwünschte Verhaltensmuster',
        'Trauerbewältigung',
        'Kindheitserlebnisse aufarbeiten',
        'Zwangsstörungen- und Erkrankungen',
        'Stressbewältigung, Prüfungsangst, Lern- und Konzentrationsstörungen',
      ],
    }),
    mounted () {
      const param = new URLSearchParams(window.location.search)
      if (param.has('focus')) {
        this.$vuetify.goTo('#' + param.get('focus'))
        window.history.pushState({}, document.title, '/')
      }
    },
  }
</script>
